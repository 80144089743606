export const inspectionTypes = {

  
  GET_VEHICLE_INSPECTION_PURPOSE_STARTED: "GET_VEHICLE_INSPECTION_PURPOSE_STARTED",
  GET_VEHICLE_INSPECTION_PURPOSE_SUCCESS: "GET_VEHICLE_INSPECTION_PURPOSE_SUCCESS",
  GET_VEHICLE_INSPECTION_PURPOSE_FAILED: "GET_VEHICLE_INSPECTION_PURPOSE_FAILED",

  GET_VEHICLE_INSPECTION_POINT_STARTED: "GET_VEHICLE_INSPECTION_POINT_STARTED",
  GET_VEHICLE_INSPECTION_POINT_SUCCESS: "GET_VEHICLE_INSPECTION_POINT_SUCCESS",
  GET_VEHICLE_INSPECTION_POINT_FAILED: "GET_VEHICLE_INSPECTION_POINT_FAILED",
  
  GET_VEHICLE_INSPECTION_FIELDS_STARTED: "GET_VEHICLE_INSPECTION_FIELDS_STARTED",
  GET_VEHICLE_INSPECTION_FIELDS_SUCCESS: "GET_VEHICLE_INSPECTION_FIELDS_SUCCESS",
  GET_VEHICLE_INSPECTION_FIELDS_FAILED: "GET_VEHICLE_INSPECTION_FIELDS_FAILED",

  GET_VEHICLE_PURPOSE_STARTED: "GET_VEHICLE_PURPOSE_STARTED",
  GET_VEHICLE_PURPOSE_SUCCESS: "GET_VEHICLE_PURPOSE_SUCCESS",
  GET_VEHICLE_PURPOSE_FAILED: "GET_VEHICLE_PURPOSE_FAILED", 

  GET_VEHICLE_INSPECTION_SHEET_STARTED: "GET_VEHICLE_INSPECTION_SHEET_STARTED",
  GET_VEHICLE_INSPECTION_SHEET_SUCCESS: "GET_VEHICLE_INSPECTION_SHEET_SUCCESS",
  GET_VEHICLE_INSPECTION_SHEET_FAILED: "GET_VEHICLE_INSPECTION_SHEET_FAILED", 

  GET_INSPECTION_GRADES_STARTED: "GET_INSPECTION_GRADES_STARTED",
  GET_INSPECTION_GRADES_SUCCESS: "GET_INSPECTION_GRADES_SUCCESS",
  GET_INSPECTION_GRADES_FAILED: "GET_INSPECTION_GRADES_FAILED", 

  UPDATE_SELECTED_STARTED: "UPDATE_SELECTED_STARTED",
  UPDATE_SELECTED_SUCCESS: "UPDATE_SELECTED_SUCCESS",
  UPDATE_SELECTED_FAILED: "UPDATE_SELECTED_FAILED",

  CREATE_VEHICLE_INSPECTION_STARTED: "CREATE_VEHICLE_INSPECTION_STARTED",
  CREATE_VEHICLE_INSPECTION_SUCCESS: "CREATE_VEHICLE_INSPECTION_SUCCESS",
  CREATE_VEHICLE_INSPECTION_FAILED: "CREATE_VEHICLE_INSPECTION_FAILED",

  GET_DRAFT_INSPECTIONS_STARTED: "GET_DRAFT_INSPECTIONS_STARTED",
  GET_DRAFT_INSPECTIONS_SUCCESS: "GET_DRAFT_INSPECTIONS_SUCCESS",
  GET_DRAFT_INSPECTIONS_FAILED: "GET_DRAFT_INSPECTIONS_FAILED",

  COMPLETE_VEHICLE_INSPECTION_ITEMS_STARTED: "COMPLETE_VEHICLE_INSPECTION_ITEMS_STARTED",
  COMPLETE_VEHICLE_INSPECTION_ITEMS_SUCCESS: "COMPLETE_VEHICLE_INSPECTION_ITEMS_SUCCESS",
  COMPLETE_VEHICLE_INSPECTION_ITEMS_FAILED: "COMPLETE_VEHICLE_INSPECTION_ITEMS_FAILED",

  SAVE_VEHICLE_INSPECTION_DRAFT_STARTED:"SAVE_VEHICLE_INSPECTION_DRAFT_STARTED",
  SAVE_VEHICLE_INSPECTION_DRAFT_SUCCESS:"SAVE_VEHICLE_INSPECTION_DRAFT_SUCCESS",
  SAVE_VEHICLE_INSPECTION_DRAFT_FAILED:"SAVE_VEHICLE_INSPECTION_DRAFT_FAILED",

  RETRIEVE_DRAFT_STARTED:"RETRIEVE_DRAFT_STARTED",
  RETRIEVE_DRAFT_SUCCESS:"RETRIEVE_DRAFT_SUCCESS",
  RETRIEVE_DRAFT_FAILED:"RETRIEVE_DRAFT_FAILED",

  UPDATE_STEP_PROGRESS_STARTED:"UPDATE_STEP_PROGRESS_STARTED",
  UPDATE_STEP_PROGRESS_SUCCESS:"UPDATE_STEP_PROGRESS_SUCCESS",
  UPDATE_STEP_PROGRESS_FAILED:"UPDATE_STEP_PROGRESS_FAILED"

}